import { useEffect, useState } from "react";

const BloemenPopupAanpassen = (props) => {
    const [naamBloem, setNaamBloem] = useState(props.naam);
    const [descriptieBloem, setDescriptieBloem] = useState(props.descriptie);
    const [herkomstBloem, setHerkomstBloem] = useState(props.herkomst);

    const naamBloemHandler = (e) => {
        const updatedData = {
            naam: e.target.value,
            descriptie: descriptieBloem, // Include the other fields
            herkomst: herkomstBloem,     // Include the other fields
        };
        setNaamBloem(e.target.value);
        props.onDataUpdate(updatedData);
    };

    const descriptieBloemHandler = (e) => {
        const updatedData = {
            naam: naamBloem,             // Include the other fields
            descriptie: e.target.value,
            herkomst: herkomstBloem,     // Include the other fields
        };
        setDescriptieBloem(e.target.value);
        props.onDataUpdate(updatedData);
    };

    const herkomstBloemhandler = (e) => {
        const updatedData = {
            naam: naamBloem,             // Include the other fields
            descriptie: descriptieBloem, // Include the other fields
            herkomst: e.target.value,
        };
        setHerkomstBloem(e.target.value);
        props.onDataUpdate(updatedData);
    };

    return (
        <>
            <p>
                <strong>Naam:</strong>
                <input value={naamBloem} onChange={naamBloemHandler} />
            </p>
            <p>
                <strong>Descriptie:</strong>
                <input value={descriptieBloem} onChange={descriptieBloemHandler} />
            </p>
            <p>
                <strong>Herkosmt:</strong>
                <input value={herkomstBloem} onChange={herkomstBloemhandler} />
            </p>
        </>
    );
};

export default BloemenPopupAanpassen;