import React, {useState} from "react";

const KlantSummaryAanpassen = (props) => {
    const [naamKlant, setNaamKlant] = useState(props.naam)
    const [bedrijfKlant, setBedrijfKlant] = useState(props.bedrijf)
    const [landKlant, setLandKlant] = useState(props.land)
    const [stadKlant, setStadKlant] = useState(props.stad)
    const [adresKlant, setAdresKlant] = useState(props.adres)
    const [functieKlant, setFunctieKlant] = useState(props.functie)
    const [emailKlant, setEmailKlant] = useState(props.email)
    const [telKlant, setTelKlant] = useState(props.telefoon)
    const [kvkKlant, setKvkKlant] = useState(props.kvk)
    const [wechatKlant, setWechatKlant] = useState(props.wechat)
    const naamKlantHandler = e => {
        const updatedData = {
            naam: e.target.value,
            bedrijf: bedrijfKlant,
            land: landKlant,
            stad: stadKlant,
            adres: adresKlant,
            functie: functieKlant,
            email: emailKlant,
            tel: telKlant,
            kvk: kvkKlant,
            wechat: wechatKlant
        }
        setNaamKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    const bedrijfKlantHanlder = e => {
        const updatedData = {
            naam: naamKlant,
            bedrijf: e.target.value,
            land: landKlant,
            stad: stadKlant,
            adres: adresKlant,
            functie: functieKlant,
            email: emailKlant,
            tel: telKlant,
            kvk: kvkKlant,
            wechat: wechatKlant
        }
        setBedrijfKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    const landKlantHandler = e => {
        const updatedData = {
            naam: naamKlant,
            bedrijf: bedrijfKlant,
            land: e.target.value,
            stad: stadKlant,
            adres: adresKlant,
            functie: functieKlant,
            email: emailKlant,
            tel: telKlant,
            kvk: kvkKlant,
            wechat: wechatKlant
        }
        setLandKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    const stadKlantHandler = e => {
        const updatedData = {
            naam: naamKlant,
            bedrijf: bedrijfKlant,
            land: landKlant,
            stad: e.target.value,
            adres: adresKlant,
            functie: functieKlant,
            email: emailKlant,
            tel: telKlant,
            kvk: kvkKlant,
            wechat: wechatKlant
        }
        setStadKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    const adresKlantHandler = e => {
        const updatedData = {
            naam: naamKlant,
            bedrijf: bedrijfKlant,
            land: landKlant,
            stad: stadKlant,
            adres: e.target.value,
            functie: functieKlant,
            email: emailKlant,
            tel: telKlant,
            kvk: kvkKlant,
            wechat: wechatKlant
        }
        setAdresKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    const functieKlantHandler = e => {
        const updatedData = {
            naam: naamKlant,
            bedrijf: bedrijfKlant,
            land: landKlant,
            stad: stadKlant,
            adres: adresKlant,
            functie: e.target.value,
            email: emailKlant,
            tel: telKlant,
            kvk: kvkKlant,
            wechat: wechatKlant
        }
        setFunctieKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    const emailKlantHandler = e => {
        const updatedData = {
            naam: naamKlant,
            bedrijf: bedrijfKlant,
            land: landKlant,
            stad: stadKlant,
            adres: adresKlant,
            functie: functieKlant,
            email: e.target.value,
            tel: telKlant,
            kvk: kvkKlant,
            wechat: wechatKlant
        }
        setEmailKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    const telKlantHandler = e => {
        const updatedData = {
            naam: naamKlant,
            bedrijf: bedrijfKlant,
            land: landKlant,
            stad: stadKlant,
            adres: adresKlant,
            functie: functieKlant,
            email: emailKlant,
            tel: e.target.value,
            kvk: kvkKlant,
            wechat: wechatKlant
        }
        setTelKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    const setKvkHandler = e => {
        const updatedData = {
            naam: naamKlant,
            bedrijf: bedrijfKlant,
            land: landKlant,
            stad: stadKlant,
            adres: adresKlant,
            functie: functieKlant,
            email: emailKlant,
            tel: telKlant,
            kvk: e.target.value,
            wechat: wechatKlant
        }
        setKvkKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    const wechatHandler = e => {
        const updatedData = {
            naam: naamKlant,
            bedrijf: bedrijfKlant,
            land: landKlant,
            stad: stadKlant,
            adres: adresKlant,
            functie: functieKlant,
            email: emailKlant,
            tel: telKlant,
            kvk: kvkKlant,
            wechat: e.target.value
        }
        setWechatKlant(e.target.value)
        props.onDataUpdate(updatedData)
    }
    return (
        <>
            <p><strong>Naam: </strong><input value={naamKlant} onChange={naamKlantHandler}/></p>
            <p><strong>Bedrijf: </strong><input value={bedrijfKlant} onChange={bedrijfKlantHanlder}/></p>
            <p><strong>land: </strong><input value={landKlant} onChange={landKlantHandler}/></p>
            <p><strong>Stad: </strong><input value={stadKlant} onChange={stadKlantHandler}/></p>
            <p><strong>Adres: </strong><input value={adresKlant} onChange={adresKlantHandler}/></p>
            <p><strong>Functie: </strong><input value={functieKlant} onChange={functieKlantHandler}/></p>
            <p><strong>Email: </strong><input value={emailKlant} onChange={emailKlantHandler}/></p>
            <p><strong>Telefoonnummer: </strong><input value={telKlant} onChange={telKlantHandler}/></p>
            <p><strong>KVK nummer: </strong><input value={kvkKlant} onChange={setKvkHandler}/></p>
            <p><strong>WeChat: </strong><input value={wechatKlant} onChange={wechatHandler}/></p>
        </>
    )
}
export default KlantSummaryAanpassen