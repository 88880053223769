import React from "react";

const KlantSummary = props => {
    return (
        <div>
            <p><strong>Naam: </strong>{props.naam}</p>
            <p><strong>Bedrijf: </strong>{props.bedrijf}</p>
            <p><strong>land: </strong>{props.land}</p>
            <p><strong>Stad: </strong>{props.stad}</p>
            <p><strong>Adres: </strong>{props.adres}</p>
            <p><strong>Functie: </strong>{props.functie}</p>
            <p><strong>Email: </strong>{props.email}</p>
            <p><strong>Telefoonnummer: </strong>{props.telefoon}</p>
            <p><strong>KVK nummer: </strong>{props.kvk}</p>
            <p><strong>WeChat: </strong>{props.wechat}</p>
        </div>
    )
}
export default KlantSummary