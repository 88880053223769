import Modal from "../../../UI/Modal";
import CloseButtonModal from "../../../UI/CloseButtonModal";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import "./KlantenPopup.css"
import KlantSummary from "./KlantSummary";
import KlantSummaryAanpassen from "./KlantSummaryAanpassen";
import Axios from "axios";

const KlantenPopup = props => {
    const [klantenData, setKlantenData] = useState([])
    const [menuBarShow, setMenuBarShow] = useState(false)
    const [deleteCostumer, setDeleteCostumer] = useState(false)
    const [aanpassenKlantShow, setAanpassenKlantShow] = useState(false)
    const [klantenAanpasData, setKlantenAanpasData] = useState({})
    const showMenuBarHandler = () => {
        setMenuBarShow(true)
    }
    const showMenuBarHandlerFalse = () => {
        setMenuBarShow(false)
    }
    const showDeleteCostumer = () => {
        setDeleteCostumer(true)
    }
    const hideDeleteCostumer = () => {
        setDeleteCostumer(false)
    }

    const showAanpassingen = () => {
        setAanpassenKlantShow(true)
    }
    const hideAanpassingen = () => {
        setAanpassenKlantShow(false)
    }
    const klantIdNew = (window.location.pathname.slice(9))


    const deleteKlant = (e) => {
        e.preventDefault()
        console.log("klant verwijderen")
        Axios.post(`/api/deleteKlant/${klantIdNew}`)
        window.open("/klanten", "_self")
    }

    const klantData = klantenData.map(klant =>
        <KlantSummary
            key={klant._id}
            naam={klant.naam}
            bedrijf={klant.bedrijf}
            land={klant.land}
            stad={klant.stad}
            adres={klant.adres}
            functie={klant.functie}
            email={klant.email}
            telefoon={klant.telefoon}
            kvk={klant.kvk}
            wechat={klant.wechat}
        />
    )
    const handleKlantenAanpassen = data => {
        setKlantenAanpasData(data)
    }
    const klantDataAanpassen = klantenData.map(klant =>
        <KlantSummaryAanpassen
            key={klant._id}
            naam={klant.naam}
            bedrijf={klant.bedrijf}
            land={klant.land}
            stad={klant.stad}
            adres={klant.adres}
            functie={klant.functie}
            email={klant.email}
            telefoon={klant.telefoon}
            kvk={klant.kvk}
            wechat={klant.wechat}
            onDataUpdate={handleKlantenAanpassen}
        />
    )

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log(klantIdNew)
                const response = await fetch(`/api/klantId/${klantIdNew}`);
                if (!response.ok) {
                    throw new Error(`Server responded with status: ${response.status}`);
                }
                const data = await response.json();
                console.log(data);
                setKlantenData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const aanpassingenKlantOpslaan = e => {
        e.preventDefault()

        try {
            Axios.post(`/api/veranderKlant/${klantIdNew}`, {
                naam: klantenAanpasData.naam,
                bedrijf: klantenAanpasData.bedrijf,
                land: klantenAanpasData.land,
                stad: klantenAanpasData.stad,
                adres: klantenAanpasData.adres,
                functie: klantenAanpasData.functie,
                email: klantenAanpasData.email,
                tel: klantenAanpasData.tel,
                kvk: klantenAanpasData.kvk,
                wechat: klantenAanpasData.wechat
            })

            window.open(`/klanten/${klantenAanpasData.kvk}`, "_self")
        } catch (err) {
            console.log(err)
        }

        hideAanpassingen(false)
    }
    return (
        <Modal>
            <div onClick={props.onClosePopup}>
                <Link to={'/klanten'}>
                    <CloseButtonModal onClosePopup={props.onClosePopup}/>
                </Link>
            </div>
            <FontAwesomeIcon icon={faEllipsisVertical} style={{color: "#000000",}} className={"ellipsis"}
                             onClick={showMenuBarHandler}
            />
            <div className={`${menuBarShow ? "menubar-klant-show" : "menubar-klant-hidden"}`}>
                <div className={"menubar-klant__item"} onClick={showAanpassingen}>
                    <p>aanpassen</p>
                </div>
                <div className={"menubar-klant__item"} onClick={showDeleteCostumer}>
                    <p>verwijderen</p>
                </div>
            </div>
            <div onClick={showMenuBarHandlerFalse}
                 className={`${aanpassenKlantShow ? "aanpassingen-klant-hidden" : "aanpassingen-klant"}`}>
                {klantData}
            </div>
            <div onClick={showMenuBarHandlerFalse}
                 className={`${aanpassenKlantShow ? "aanpassingen-klant" : "aanpassingen-klant-hidden"}`}>
                {klantDataAanpassen}
                <button id={"Aanpassingen-opslaan__klant"} onClick={aanpassingenKlantOpslaan}>Opslaan</button>
            </div>

            <div className={`${deleteCostumer ? "remove-klant" : "remove-klant-hidden"}`}>
                <h2>Weet je het zeker?</h2>
                <div className={"button-remove-klant-set"}>
                    <button id={"button-remove-klant__confirm"} onClick={deleteKlant}>Ja</button>
                    <button id={"button-remove-klant__deny"} onClick={hideDeleteCostumer}>Nee</button>
                </div>
            </div>
        </Modal>
    )
}

export default KlantenPopup