import React, {Component, useState} from "react";
import "./navbar.css";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons/faHouse";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faSeedling} from "@fortawesome/free-solid-svg-icons/faSeedling";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons/faBriefcase";
import {faCalculator, faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";


const Navbar = ({props}) => {

    const [activeMenu, setActiveMenu] = useState("");
    const {pathname} = useLocation();

    const logOut = async () => {
        try {
            const response = await fetch('api/logout', {
                method: 'POST',
                credentials: 'include' // Include credentials for session
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                // Redirect to the home page or perform any other actions after logout
            } else {
                console.log('Logout failed:', response.status, response.statusText);
            }
        } catch (err) {
            console.error('Error:', err);
        }
    }
    return (
        <div
            key={"home"}
            onClick={() => setActiveMenu("home")}
            className={"navbar-container"}>
            <Link to={"/"} style={{textDecoration: 'none'}}>
                <div className={pathname === "/" ? "active-navbar" : "navbar-item"}>
                    <FontAwesomeIcon className={"nav-icon"} icon={faHouse} size="xl" style={{
                        marginLeft: "25px", marginRight: "25px", width: "10%"
                    }}/>
                    <h2>Home</h2>
                </div>
            </Link>

            <Link to={"/klanten"} style={{textDecoration: 'none'}}>
                <div
                    className={pathname === "/klanten" || pathname === "/klanten/history" ? "active-navbar" : "navbar-item"}>
                    <FontAwesomeIcon className={"nav-icon"} icon={faUsers} size="xl" style={{
                        marginLeft: "25px", marginRight: "25px", width: "10%"
                    }}/>
                    <h2>Klanten</h2>
                </div>
            </Link>

            <Link to={"/bloemen"} style={{textDecoration: 'none'}}>
                <div className={pathname === "/bloemen" ? "active-navbar" : "navbar-item"}>
                    <FontAwesomeIcon className={"nav-icon"} icon={faSeedling} size="xl" style={{
                        marginLeft: "25px", marginRight: "25px", width: "10%"
                    }}/>
                    <h2>Bloemen</h2>
                </div>
            </Link>

            <Link to={"/onderzoek"} style={{textDecoration: 'none'}}>
                <div
                    className={pathname === "/onderzoek" || pathname === "/onderzoek/history" ? "active-navbar" : "navbar-item"}>
                    <FontAwesomeIcon className={"nav-icon"} icon={faBriefcase} size="xl" style={{
                        marginLeft: "25px", marginRight: "25px", width: "10%"
                    }}/>
                    <h2>Onderzoek</h2>
                </div>
                <Link to={"/onderzoek/history"} style={{textDecoration: 'none'}}>
                    <div
                        className={pathname === "/onderzoek" || pathname === "/onderzoek/history" ? "navbar-item-history" : "navbar-item-hidden"}>
                        <h2 className={pathname === "/onderzoek/history" ? "history-h2-active" : "history-h2"}>History</h2>
                    </div>
                </Link>
            </Link>

            <Link to={"/probleem"} style={{textDecoration: 'none'}}>
                <div className={pathname === "/probleem" ? "active-navbar" : "navbar-item"}>
                    <FontAwesomeIcon className={"nav-icon"} icon={faTriangleExclamation} size="xl"
                                     style={{marginLeft: "25px", marginRight: "25px", width: "10%"}}/>
                    <h2>Probleem</h2>
                </div>
            </Link>

            <Link to={"/"} style={{textDecoration: 'none'}} onClick={logOut}>
                <div className={"navbar-item"}>
                    <FontAwesomeIcon className={"nav-icon"} icon={faRightFromBracket} style={{color: "#000000",marginLeft: "25px", marginRight: "25px", width: "10%"}}/>
                    <h2>Uitloggen</h2>
                </div>
            </Link>
        </div>
    )

}

export default Navbar