import "./BloemenItem.css"

const BloemenItem = props => {
    return <div className={"bloem-item"} onClick={props.onClickForPopup}>
        <div onClick={props.onClickForId}>
            <img className={"picture-flower"}  src={`/${props.image}`} alt={props.image}/>`
            <h3>{props.name}</h3>
            <p>{props.description}</p>
            <p>{props.location}</p>
        </div>
    </div>
};

export default BloemenItem;