import React, {useEffect, useState} from "react";
import './probleem.css'
import {json} from "react-router-dom";


const Probleem = () => {
    function handleSubmit(e) {
        e.preventDefault()
    }

    const [mailData, setMaildata] = useState("")
    const [probleem, setProbleem] = useState("")
    const [uitleg, setUitleg] = useState("")

    function sendMail(e) {
        e.preventDefault()

        fetch("api/send-mail").then(
            response => json("succes")
        ).then(
            data => {
                setMaildata(data)
                console.log(mailData)
            }
        )
    }

    const probleemChange = (e) => {
        setProbleem(e.target.value)
    }
    const uitlegChange = (e) => {
        setProbleem(e.target.value)
    }
    return (
        <div className={"base-container"}>
            <form onSubmit={handleSubmit} className={"form-probleem"} action={"submit"}>
                <div className={"form-set"}>
                    <label htmlFor={"probleem-input"}>
                        Probleem:
                    </label>
                    <input type={"text"} id={"probleem-input"} name={"username"}/>
                </div>

                <div className={"form-set"}>
                    <label htmlFor={"uitleg-input"}>
                        Uitleg:
                    </label>
                    <textarea rows={40} cols={74} id={"textarea-probleem"} onChange={uitlegChange} name={"password"}></textarea>
                </div>

                <input id={"probleem-verzenden"} type={"submit"} value={"submit"}
                onClick={sendMail}/>
            </form>

        </div>
    )
}

export default Probleem