import "./KlantenItem.css";
import React from "react";

const KlantenItem = React.forwardRef((props, ref) => {
    return <li value={props.name} ref={ref} className={"list-costumer"} onClick={props.onPopupKlantenShow}>
        <p>{props.name}</p>
        <p>{props.email}</p>
        <p>{props.tel}</p>
    </li>
})

export default KlantenItem