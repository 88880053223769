import React, {useEffect, useState} from "react";
import "./klanten.css"
import KlantenItem from "./KlantenItem/KlantenItem";
import {Link} from "react-router-dom";

const Klanten = (props) => {
    const [klantenData, setKlantenData] = useState([])


    useEffect(() => {
        fetch("api/getKlanten")
            .then(res => {
                return res.json()
            })
            .then(data => {
                console.log(data)
                setKlantenData(data)
            })
    }, [])

    const ListKlanten = klantenData.map(klant =>
            <Link to={`/klanten/${klant.kvk}`} className={"klant-link"}>
                <KlantenItem
                    key={klant._id}
                    id={klant._id}
                    name={klant.naam}
                    email={klant.email}
                    tel={klant.telefoon}
                    onPopupKlantenShow={props.onShowPopupKlanten}
                />
            </Link>
    )

    return (
        <>
            <div className={"base-container"}>

                <Link to={"/voeg-klant-toe"} className={"button-container-klanten"}>
                    <button id={"voeg-klant-toe"}>Voeg klant toe</button>
                </Link>

                {ListKlanten}
            </div>
        </>

    )
}

export default Klanten