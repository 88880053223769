import React, {useEffect, useState} from "react";
import Footer from "./components/footer/footer";
import Navbar from "./components/navbar/navbar"
import Home from "./components/home/home";
import Klanten from "./components/klanten/klanten";
import {Route, Routes} from "react-router-dom";
import Bloemen from "./components/Bloemen/bloemen";
import Onderzoek from "./components/Onderzoek/onderzoek";
import Boekhouden from "./components/Boekhouden/boekhouden";
import Probleem from "./components/Probleem/probleem";
import LoginPage from "./components/login/loginPage";
import BloemenPopup from "./components/Bloemen/BloemenPopup/BloemenPopup";
import AfsprakenPopup from "./components/home/AfsprakenPopup/AfsprakenPopup";
import KlantenPopup from "./components/klanten/KlantenPopup/KlantenPopup";
import History from "./components/Onderzoek/History/History";
import HistoryFormulier from "./components/Onderzoek/History/HistoryFormulier";
import VoegKlantToe from "./components/klanten/VoegKlantToe/VoegKlantToe";
import BloemToevoegen from "./components/Bloemen/BloemToevoegen/BloemToevoegen";
import Cookies from 'js-cookie'

function App() {
    const [popupFlowerShown, setPopupFlowerShown] = useState(false)
    const [afsprakenPopupShown, setAfsprakenPopupShown] = useState(false)
    const [klantenPopupShown, setKlantenPopupShown] = useState(false)
    const [klantenToevoegPopupShown, setKlantenToevoegPopupShown] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const showPopupBloemHandler = () => {
        setPopupFlowerShown(true)
    }
    const showPopupAfspraakHandler = () => {
        setAfsprakenPopupShown(true)
    }
    const showPopupKlantenHandler = () => {
        setKlantenPopupShown(true)
    }
    const showPopupKlantToevoegen = () => {
        setKlantenToevoegPopupShown(true)
    }
    const logSessionToken = () => {
        const sessionToken = Cookies.get('sessionToken');
        if (sessionToken) {
            console.log("clicked: " + sessionToken)
        }
    }
    useEffect(() => {
        const sessionToken = Cookies.get('sessionToken');
        console.log("sessionToken:", sessionToken)
        if (sessionToken) {
            setLoggedIn(true)
        }
        console.log(sessionToken)
        console.log( "test ")
    }, [])
    const handleLogout = () => {
        setLoggedIn(false)
    }
    const hidePopup = () => {
        setPopupFlowerShown(false)
        setAfsprakenPopupShown(false)
        setKlantenPopupShown(false)
        setKlantenToevoegPopupShown(false)
    }

    const logoutHandler = async () => {

        try {
            const response = await fetch("/logout", {
                method: "POST",
                headers: {
                    "Content-Type": 'application/json'
                },
            });

            if (response.ok) {
                window.location.reload()
            } else {
                console.error("Logout Failed")
            }
        } catch (err) {
            console.error("Error during logout:", err)
        }
    }

    return (
        <>
            {loggedIn ? (
                <><Navbar onLogout={logoutHandler}/>
                    {afsprakenPopupShown && <AfsprakenPopup onClosePopup={hidePopup}/>}
                    <Routes>
                        <Route
                            path={'/'}
                            element={<Home
                                onShowPopupAfspraak={showPopupAfspraakHandler}
                            />}
                        />
                        <Route path={'/klanten'} element={<Klanten
                            onShowPopupKlanten={showPopupKlantenHandler}
                        />}/>
                        <Route path={'/klanten/:id'} element={<KlantenPopup onClosePopup={hidePopup}/>}/>
                        <Route path={"voeg-klant-toe"} element={<VoegKlantToe/>}/>
                        <Route path={'/bloemen'} element={<Bloemen onShowPopupBloem={showPopupBloemHandler}/>}/>
                        <Route path={'/bloemen/:id'} element={<BloemenPopup/>}/>
                        <Route path={'/bloemen/invoer'} element={<BloemToevoegen/>}/>
                        <Route path={'/onderzoek'} element={<Onderzoek/>}/>
                        <Route path={"/onderzoek/history"} element={<History/>}/>
                        <Route path={"/onderzoek/history/:id"} element={<HistoryFormulier/>}/>
                        <Route path={'/boekhouden'} element={<Boekhouden/>}/>
                        <Route path={'/probleem'} element={<Probleem/>}/>
                    </Routes>

                    <Footer/></>
            ) : (
                <Routes>
                <Route path={'/'} element={<LoginPage onLogin={logSessionToken}/>} />
            </Routes>)}
        </>
    )
}

export default App;