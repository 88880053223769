import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Axios from "axios";
import Modal from "../../../UI/Modal";

const HistoryFormulier = props => {
    const [deleteOnderzoek, setDeleteOnderzoek] = useState(false)
    const [onderzoek, setOnderzoek] = useState({})
    const [onderzoekId, setOnderzoekId] = useState("")
    const {id} = useParams();

    const deleteOnderzoekTrue = e => {
        e.preventDefault()
        setDeleteOnderzoek(true)
    }
    const deleteOnderzoekFalse = e => {
        e.preventDefault();
        setDeleteOnderzoek(false)
    }
    useEffect(() => {
        fetch(`/api/getOnderzoek/${id}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                if (Array.isArray(data) && data.length > 0) {
                    setOnderzoek(data[0])
                    setOnderzoekId(gekozenOnderzoek.id)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const removeResearch = e => {
        e.preventDefault()

        Axios.post(`/api/deleteOnderzoek/${id}`)
        window.open("/onderzoek/history", "_self")
    }
    let gekozenOnderzoek = onderzoek
    return (
        <div className={"base-container"}>
            {onderzoek !== null ? (
                <>
                    <label className={"relatie-list"}>
                        Relatie:
                        <select className={"select-dropdown"}>
                            <option>{gekozenOnderzoek.relatie}</option>
                        </select>
                    </label>

                    <form className={"form-onderzoek"}>
                        <div className={"form-container"}>
                            <div className={"form-onderzoek__section-left"}>
                                <div className={"form-onderzoek__naw-klant"}>
                                    <div className={"form-set__naw-klant"}>
                                        <label>Naam opdrachtgever: </label> <input value={gekozenOnderzoek.opdrachtgever} type={"text"}/>
                                    </div>
                                    <div className={"form-set__naw-klant"}>
                                        <label>Adres: </label><input type={"text"} value={gekozenOnderzoek.adres}/>
                                    </div>
                                    <div className={"form-set__naw-klant"}>
                                        <label>postcode: </label><input type={"text"}
                                                                        value={gekozenOnderzoek.postcode}/>
                                    </div>
                                    <div className={"form-set__naw-klant"}>
                                        <label>Woonplaats: </label><input type={"text"}
                                                                          value={gekozenOnderzoek.woonplaats}/>
                                    </div>
                                </div>
                                <div className={"form-onderzoek__data"}>
                                    <div className={"form-set__data unique-set"}>
                                        <label>Partij: </label><input id={"partij__1"}
                                                                      value={gekozenOnderzoek.partij1}/><input
                                        id={"partij__2"} value={gekozenOnderzoek.partij2}/>
                                    </div>
                                    <div className={"form-set__data"}>
                                        <label>CertificaatNummer:</label><input value={gekozenOnderzoek.certificaatNummer}/>
                                        <label>Eigen PartijNr:</label><input value={gekozenOnderzoek.eigenPartijNummer}/>
                                    </div>
                                </div>
                                <div className="form-grid__data">
                                    {/* namen van tabellen */}
                                    <div className="empty"></div>
                                    <div className="krat-1"><strong>Krat 1</strong></div>
                                    <div className="krat-2"><strong>Krat 2</strong></div>
                                    <div className="krat-3"><strong>Krat 3</strong></div>
                                    <div className="aantal"><strong>Aantal</strong></div>
                                    <div className="te-klein"><strong>Te Klein</strong></div>
                                    <div className="te-groot"><strong>Te Groot</strong></div>
                                    <div className="schade"><strong>Schade</strong></div>
                                    <div className="zachtrot"><strong>Zachtrot</strong></div>
                                    <div className="penicillium"><strong>Penicillium</strong></div>
                                    <div className="mijten"><strong>Mijten</strong></div>
                                    <div className="fusarium"><strong>Fusarium</strong></div>
                                    <div className="steel"><strong>Steel</strong></div>
                                    <div className="spruithoogte"><strong>Spruithoogte</strong></div>
                                    <div className="water-schade"><strong>Waterschade</strong></div>
                                    <div className="bewort"><strong>Beworteld</strong></div>
                                    <div className="dubbel-ns"><strong>DubbelNS</strong></div>
                                    <div className="gewicht"><strong>Gewicht</strong></div>
                                    <div className="zwarte-spruit"><strong>Zwarte Spruit</strong></div>
                                    <div className="droge-stof"><strong>Droge Stof</strong></div>
                                    <div className="gemiddeld"><strong>Gemiddeld</strong></div>

                                    {/* vanaf hier input
                        - aantal
                        */}
                                    <div className="aantal__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.aantalKrat1}
                                        placeholder={"Aantal"}
                                    /></div>
                                    <div className="aantal__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.aantalKrat2}
                                        placeholder={"aantal"}
                                    /></div>
                                    <div className="aantal__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.aantalKrat3}
                                        placeholder={"aantal"}
                                    /></div>
                                    <div className="aantal__gem form-grid__input"><input
                                        readOnly={true}
                                        type={"number"}
                                        value={gekozenOnderzoek.aantalGem}
                                    /></div>

                                    {/* te klein */}
                                    <div className="te-klein__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.teKleinKrat1}
                                        placeholder={"Te Klein"}
                                    /></div>
                                    <div className="te-klein__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.teKleinKrat2}
                                        placeholder={"Te Klein"}
                                    /></div>
                                    <div className="te-klein__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.teKleinKrat3}
                                        placeholder={"Te Klein"}
                                    /></div>
                                    <div className="te-klein__gem form-grid__input"><input
                                        readOnly={true}
                                        type={"number"}
                                        value={gekozenOnderzoek.teKleinGem}
                                    /></div>

                                    {/* te groot */}
                                    <div className="te-groot__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.teGrootKrat1}
                                        placeholder={"Te Groot"}
                                    /></div>
                                    <div className="te-groot__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.teKleinKrat2}
                                        placeholder={"Te Groot"}
                                    /></div>
                                    <div className="te-groot__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.teGrootKrat3}
                                        placeholder={"Te Groot"}
                                    /></div>
                                    <div className="te-groot__gem form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.teGrootGem}
                                        readOnly={true}
                                    /></div>

                                    {/* schade */}
                                    <div className="schade__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.schadeKrat1}
                                        placeholder={"Schade"}
                                    /></div>
                                    <div className="schade__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.schadeKrat2}
                                        placeholder={"Schade"}
                                    /></div>
                                    <div className="schade__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.schadeKrat3}
                                        placeholder={"Schade"}
                                    /></div>
                                    <div className="schade__gem form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.schadeGem}
                                        readOnly={true}
                                    /></div>

                                    {/* zachtrot */}
                                    <div className="zachtrot__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.zachtrotKrat1}
                                        placeholder={"Zachtrot"}
                                    /></div>
                                    <div className="zachtrot__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.zachtrotKrat2}
                                        placeholder={"Zachtrot"}
                                    /></div>
                                    <div className="zachtrot__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.zachtrotKrat3}
                                        placeholder={"Zachtrot"}
                                    /></div>
                                    <div className="zachtrot__gem form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.zachtrotGem}
                                        readOnly={true}
                                    /></div>

                                    {/* Penicillium */}
                                    <div className="penicillium__krat-1 form-grid__input"><input
                                        type={"number"}
                                        placeholder={"Penicillium"}
                                        value={gekozenOnderzoek.penicilliumKrat1}

                                    /></div>
                                    <div className="penicillium__krat-2 form-grid__input"><input
                                        type={"number"}
                                        placeholder={"Penicillium"}
                                        value={gekozenOnderzoek.penicilliumKrat2}
                                    /></div>
                                    <div className="penicillium__krat-3 form-grid__input"><input
                                        type={"number"}
                                        placeholder={"Penicillium"}
                                        value={gekozenOnderzoek.penicilliumKrat3}
                                    /></div>
                                    <div className="penicillium__gem form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.penicilliumGem}
                                        readOnly={true}
                                    /></div>

                                    {/* Mijten */}
                                    <div className="mijten__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.mijtenKrat1}
                                        placeholder={"Mijten"}
                                    /></div>
                                    <div className="mijten__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.mijtenKrat2}
                                        placeholder={"Mijten"}
                                    /></div>
                                    <div className="mijten__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.mijtenKrat3}
                                        placeholder={"Mijten"}
                                    /></div>
                                    <div className="mijten__gem form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.mijtenGem}
                                        readOnly={true}/>
                                    </div>

                                    {/* fusarium */}
                                    <div className="fusarium__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.fusariumKrat1}
                                        placeholder={"Fusarium"}
                                    /></div>
                                    <div className="fusarium__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.fusariumKrat2}
                                        placeholder={"Fusarium"}
                                    /></div>
                                    <div className="fusarium__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.fusariumKrat3}
                                        placeholder={"Fusarium"}
                                    /></div>
                                    <div className="fusarium__gem form-grid__input"><input
                                        type={"number"}
                                        readOnly={true}
                                        value={gekozenOnderzoek.fusariumGem}
                                    /></div>

                                    {/* STEEL */}
                                    <div className="steel__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.steelKrat1}
                                        placeholder={"Steel"}
                                    /></div>
                                    <div className="steel__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.steelKrat2}
                                        placeholder={"Steel"}
                                    /></div>
                                    <div className="steel__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.steelKrat3}
                                        placeholder={"Steel"}
                                    /></div>
                                    <div className="steel__gem form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.steelGem}
                                    /></div>

                                    {/* Spruithoogte */}
                                    <div className="spruithoogte__krat-1 form-grid__input"><select>
                                        <option
                                            value={gekozenOnderzoek.spruithoogteKrat1}>{gekozenOnderzoek.spruithoogteKrat1}</option>

                                    </select></div>
                                    <div className="spruithoogte__krat-2 form-grid__input"><select>
                                        <option
                                            value={gekozenOnderzoek.spruithoogteKrat2}>{gekozenOnderzoek.spruithoogteKrat2}</option>
                                    </select></div>
                                    <div className="spruithoogte__krat-3 form-grid__input"><select>
                                        <option
                                            value={gekozenOnderzoek.spruithoogteKrat3}>{gekozenOnderzoek.spruithoogteKrat3}</option>
                                    </select></div>
                                    <div className="spruithoogte__gem form-grid__input"><input
                                        value={"n.v.t."}
                                        readOnly={true}/></div>

                                    {/* waterschade */}
                                    <div className="water-schade__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.waterschadeKrat1}
                                        placeholder={"Waterschade"}
                                    /></div>
                                    <div className="water-schade__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.waterschadeKrat2}
                                        placeholder={"Waterschade"}
                                    /></div>
                                    <div className="water-scahde__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.waterschadeKrat3}
                                        placeholder={"Waterschade"}
                                    /></div>
                                    <div className="water-schade__gem form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.waterschadeGem}
                                        readOnly={true}
                                    /></div>

                                    {/* beworteld */}
                                    <div className="bewort__krat-1 form-grid__input"><select>
                                        <option
                                            value={gekozenOnderzoek.beworteldKrat1}>{gekozenOnderzoek.beworteldKrat1}</option>
                                    </select></div>
                                    <div className="bewort__krat-2 form-grid__input"><select>
                                        <option
                                            value={gekozenOnderzoek.beworteldKrat2}>{gekozenOnderzoek.beworteldKrat2}</option>
                                    </select></div>
                                    <div className="bewort__krat-3 form-grid__input"><select>
                                        <option
                                            value={gekozenOnderzoek.beworteldKrat3}>{gekozenOnderzoek.beworteldKrat3}</option>
                                    </select></div>
                                    <div className="bewort__gem form-grid__input"><input
                                        readOnly={true}
                                        value={"n.v.t."}
                                    /></div>

                                    {/* DubbelNs */}
                                    <div className="dubbel-ns__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.dubbelNSKrat1}
                                        placeholder={"DubbelNs"}
                                    /></div>
                                    <div className="dubbel-ns__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.dubbelNsKrat2}
                                        placeholder={"DubbelNs"}
                                    /></div>
                                    <div className="dubbel-ns__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.dubbelNsKrat3}
                                        placeholder={"DubbelNs"}
                                    /></div>
                                    <div className="dubbel-ns__gem form-grid__input"><input
                                        readOnly={true}
                                        type={"number"}
                                        value={gekozenOnderzoek.dubbelNsGem}
                                    /></div>

                                    {/* gewicht */}
                                    <div className="gewicht__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.gewichtKrat1}
                                        placeholder={"Gewicht"}
                                    /></div>
                                    <div className="gewicht__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.gewichtKrat2}
                                        placeholder={"Gewicht"}
                                    /></div>
                                    <div className="gewicht__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.gewichtKrat3}
                                        placeholder={"Gewicht"}
                                    /></div>
                                    <div className="gewicht__gem form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.gewichtGem}
                                        readOnly={true}
                                    /></div>

                                    <div className="zwarte-spruit__krat-1 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.zwarteSpruitKrat1}
                                        placeholder={"Zwarte Spruit"}
                                    /></div>
                                    <div className="zwarte-spruit__krat-2 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.zwarteSpruitKrat2}
                                        placeholder={"Zwarte Spruit"}
                                    /></div>
                                    <div className="zwarte-spruit__krat-3 form-grid__input"><input
                                        type={"number"}
                                        value={gekozenOnderzoek.zwarteSpruitKrat3}
                                        placeholder={"Zwarte Spruit"}
                                    /></div>
                                    <div className="zwarte-spruit__gem form-grid__input"><input
                                        readOnly={true}
                                        type={"number"}
                                        value={gekozenOnderzoek.zwarteSpruitGem}
                                    /></div>

                                    {/* droge stof */}
                                    <div className="droge-stof__input form-grid__input"><input
                                        type={"text"}
                                        placeholder={"Droge Stof"}
                                        value={gekozenOnderzoek.drogeStof}
                                    /></div>
                                </div>
                            </div>

                            <div className={"form-onderzoek__section-right"}>
                                <div className={"form-column__data"}>
                                    <div className={"form-set__column"}>
                                        <label>Datum Keuring</label>
                                        <input
                                            value={gekozenOnderzoek.datumKeuring}
                                            type={"date"}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Soort</label>
                                        <input
                                            placeholder={"Soort"}
                                            value={gekozenOnderzoek.soort}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Maat</label>
                                        <input
                                            placeholder={"Maat"}
                                            value={gekozenOnderzoek.maat}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Oogstjaar</label>
                                        <input
                                            type={"number"}
                                            placeholder={"Oogstjaar"}
                                            value={gekozenOnderzoek.oogstjaar}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Koopbrief</label>
                                        <input
                                            placeholder={"Koopbrief"}
                                            value={gekozenOnderzoek.koopbrief}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Vrachtbrief</label>
                                        <input
                                            placeholder={"Vrachtbrief"}
                                            value={gekozenOnderzoek.vrachtbrief}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Ontvangst Datum</label>
                                        <input
                                            value={gekozenOnderzoek.ontvangstDatum}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Laad Datum</label>
                                        <input
                                            value={gekozenOnderzoek.laadDatum}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Keuring</label>
                                        <input
                                            placeholder={"Keuring"}
                                            value={gekozenOnderzoek.keuring}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Keurmeester</label>
                                        <select value={gekozenOnderzoek.keurmeester}>
                                            <option value={gekozenOnderzoek.keurmeester}>{gekozenOnderzoek.keurmeester}</option>
                                        </select>
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Aantal Stuks</label>
                                        <input
                                            type={"number"}
                                            placeholder={"Aantal Stuks"}
                                            value={gekozenOnderzoek.aantalStuks}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Aantal Kratten</label>
                                        <input
                                            type={"number"}
                                            placeholder={"Aantal Kratten"}
                                            value={gekozenOnderzoek.aantalKratten}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Aantal Per Krat</label>
                                        <input
                                            type={"number"}
                                            placeholder={"Aantal Per Krat"}
                                            value={gekozenOnderzoek.aantalPerKrat}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Herkomst</label>
                                        <input
                                            placeholder={"Herkomst"}
                                            value={gekozenOnderzoek.herkomst}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Prev.ontsm</label>
                                        <input
                                            placeholder={"Prev.ontsm"}
                                            value={gekozenOnderzoek.prevOntsm}
                                        />
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Indruk</label>
                                        <select>
                                            <option disabled selected value>{gekozenOnderzoek.indruk}</option>
                                        </select>
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Gespoeld</label>
                                        <select>
                                            <option disabled selected value>{gekozenOnderzoek.gespoeld}</option>
                                        </select>
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Behouden</label>
                                        <select>
                                            <option disabled selected value>{gekozenOnderzoek.behouden}</option>
                                        </select>
                                    </div>
                                    <div className={"form-set__column"}>
                                        <label>Foto gemaakt</label>
                                        <select>
                                            <option disabled selected value>{gekozenOnderzoek.fotoGemaakt}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"form-opmerkingen"}>
                            <label>Opmerkingen</label>
                            <textarea name="" id="opmerkingen-input" cols="150" rows="3"
                                      value={gekozenOnderzoek.opmerking}></textarea>
                        </div>

                        <button className={"remove-research"} onClick={deleteOnderzoekTrue}>Verwijderen</button>
                    </form>
                    {deleteOnderzoek === true ? (
                    <Modal>
                        <div className={`${deleteOnderzoek ? "remove-klant" : "remove-klant-hidden"}`}>
                            <h2>Weet je het zeker?</h2>
                            <div className={"button-remove-klant-set"}>
                                <button id={"button-remove-klant__confirm"} onClick={removeResearch}>Ja</button>
                                <button id={"button-remove-klant__deny"} onClick={deleteOnderzoekFalse}>Nee</button>
                            </div>
                        </div>
                    </Modal>
                        ) : <></>}
                </>
            ) : (
                <p>loading...</p>
            )}
        </div>
    )
}
export default HistoryFormulier