import {Link, useParams} from "react-router-dom";
import HistoryItem from "./HistoryItem";
import {useEffect, useState} from "react";

const History = () => {
    const [allOnderzoeken, setAllOnderzoeken] = useState([])
    const [geselecteerdeOnderzoekData, setGeselecteerdeOnderzoekData] = useState("")
    useEffect(() => {
        fetch("/api/getAllOnderzoeken")
            .then(res => {
                return res.json()
            })
            .then(data => {
                console.log(data)
                setAllOnderzoeken(data)
            })
    }, [])
    const handleOnderzoekSelect = (e) => {
        const selectedId = e.target.value
        const selectedOnderzoek = allOnderzoeken.find((onderzoek) => onderzoek._id === selectedId)

        if (selectedOnderzoek) {
            setGeselecteerdeOnderzoekData(selectedOnderzoek)
            console.log(selectedOnderzoek)
            console.log("veranderd")
        } else {
            console.log("Klant niet gevonden")
        }
    }
    const id = useParams()
    console.log(id)
    const onderzoekenLijst = allOnderzoeken.map(onderzoek =>
    <Link to={`/onderzoek/history/${onderzoek._id}`} className={"klant-link"}>
        <HistoryItem
            id={onderzoek._id}
            value={onderzoek._id}
            name={onderzoek.relatie}
            bedrijf={onderzoek.bedrijf}
        />
    </Link>
    )

    return (
        <div className={"base-container"}>
            {onderzoekenLijst}
        </div>
    )
}
export default History;