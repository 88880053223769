import React, {Component} from "react";
import "./footer.css"

class Footer extends React.Component {

    render() {
        return (
            <div className={"footer-container"}>
            <p>&copy;Majel - {year}</p>
            </div>
        )
    }
}
let today = new Date(),
    year = today.getFullYear()


export default Footer;